import { useEffect, useState } from 'react';
import axios from 'axios';
import DummyMain from './DummyMain';
import { useAuthContext } from './AuthContext';

/**
 * 初回ログインの場合はログインを拒否
 * @param param0
 * @returns
 */
const FirstLoginCheck = ({ children }: any) => {
  const [registerd, setRegisterd] = useState<boolean | null>(null);

  const { logout } = useAuthContext();

  useEffect(() => {
    const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000';
    axios.get(api_base_url + 'livecreatorPortalGetFirstLoginOrNot').then((response) => {
      if (!response.data.first_login) {
        axios.get(api_base_url + 'livecreatorPortalGetRegisterdOrNot').then((response) => {
          if (response.data.registerd) {
            setRegisterd(true);
          } else {
            setRegisterd(false);
          }
        });
      } else {
        setRegisterd(false);
      }
    });
  }, []);

  if (registerd == null) {
    // 読み込み前はloadingと出す
    return <DummyMain />;
  }
  if (!registerd) {
    // 初回ログイン時の場合は、ログインを拒否
    logout().then(() => {
      alert('【エラー】登録されていないクリエイターです。');
      window.location.href = '/';
    });
  } else {
    return children;
  }
};

export default FirstLoginCheck;
