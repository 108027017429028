import './App.css';
import { Routes, Route } from 'react-router-dom';
import { Account, Achievements, Score, Dashbord } from './components/index';

const MainPageRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashbord />} /> {/* ダッシュボード */}
      <Route path="/achievements" element={<Achievements />} /> {/* 配信実績 */}
      <Route path="/score/:channel_id" element={<Score />} /> {/* 成績確認 */}
      <Route path="/account" element={<Account />} /> {/* アカウント設定 */}
    </Routes>
  );
};

export default MainPageRoutes;
