import {
  Button,
  CircularProgress,
  Divider,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';
import InfoIcon from '@mui/icons-material/Info';
import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';
import NewsType from '../../types/NewsType';
import SimpleReportType from '../../types/SimpleReportType';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  ...theme.applyStyles('dark', {
    backgroundColor: '#1A2027',
  }),
}));

/**
 * ダッシュボード画面
 * @returns ダッシュボードのJSX
 */
const Dashbord = () => {
  const [simpleReport, setSimpleReport] = useState<SimpleReportType | null>(null);
  const [news, setNews] = useState<Array<NewsType> | null>(null);

  /**
   * 配信時間（秒）を時刻形式に変換する
   *
   * @param seconds
   * @returns
   */
  function secToHMS(seconds: number) {
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    let hh;
    // hour が3桁以上の場合は左0埋めをしない
    if (hour < 100) {
      hh = `00${hour}`.slice(-2);
    } else {
      hh = hour;
    }
    const mm = `00${min}`.slice(-2);
    const ss = `00${sec}`.slice(-2);
    const time = `${hh}:${mm}:${ss}`;
    return time;
  }

  /**
   * 初期処理
   */
  useEffect(() => {
    const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000/';
    // 簡易レポートを取得
    axios.get(api_base_url + 'livecreatorPortalGetSimpleReport').then((response) => {
      setSimpleReport(response.data);
    });
    // お知らせを取得
    axios.get(api_base_url + 'livecreatorPortalGetNewslist').then((response) => {
      setNews(response.data);
    });
  }, []);

  // データが取れる前は処理中のアイコンを表示
  if (simpleReport == null || news == null) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid size={{ xs: 4, sm: 8, md: 6 }}>
            <Item
              sx={{
                p: 2,
              }}
            >
              <Grid
                container
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle1" gutterBottom align="left">
                  <QueryStatsIcon fontSize="small" style={{ verticalAlign: -3 }} />
                  配信実績レポート
                </Typography>

                <Typography variant="caption" align="right">
                  配信時間・日数には当日分の速報値を含んでいます
                </Typography>
              </Grid>
              <Grid container p={1}>
                <Grid size={6} borderRight={'1px solid #CCC;'} p={1}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="center"
                    sx={{ fontWeight: 'bold' }}
                  >
                    昨日の配信時間
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom align="right">
                    {secToHMS(simpleReport.live_history.yesterday.total_time_seconds)}
                  </Typography>
                </Grid>
                <Grid size={6} p={1}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="center"
                    sx={{ fontWeight: 'bold' }}
                  >
                    今月の有効配信日数
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom align="right">
                    {simpleReport.live_history.this_month.valid_total_days_num}日
                  </Typography>
                </Grid>
                <Grid size={6} borderRight={'1px solid #CCC;'} p={1}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="center"
                    sx={{ fontWeight: 'bold' }}
                  >
                    今月の合計配信時間
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom align="right">
                    {secToHMS(simpleReport.live_history.this_month.total_time_seconds)}
                  </Typography>
                </Grid>
                <Grid size={6} p={1}>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="center"
                    sx={{ fontWeight: 'bold' }}
                  >
                    今月の有効配信時間
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom align="right">
                    {secToHMS(simpleReport.live_history.this_month.valid_total_time_seconds)}
                  </Typography>
                </Grid>
              </Grid>
              <Divider />
              <Grid size={{ xs: 4, sm: 8, md: 12 }} textAlign={'right'} padding={1}>
                <Button
                  variant="contained"
                  size="small"
                  component={RouterLink}
                  to={'/achievements'}
                  sx={{ bgcolor: '#FF4455' }}
                >
                  詳細レポート
                </Button>
              </Grid>
            </Item>
          </Grid>
          <Grid size={{ xs: 4, sm: 8, md: 6 }}>
            <Item
              sx={{
                p: 2,
              }}
            >
              <Grid
                container
                sx={{
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="subtitle1" gutterBottom align="left">
                  <CurrencyYenIcon fontSize="small" style={{ verticalAlign: -3 }} />
                  収益レポート
                </Typography>

                <Typography variant="caption" align="right">
                  報酬額には当日分の速報値を含んでいます
                </Typography>
              </Grid>
              <Grid container p={1}>
                <Grid
                  size={simpleReport.revenue.this_month.total_time_revenue != null ? 6 : 12}
                  p={1}
                >
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    align="center"
                    sx={{ fontWeight: 'bold' }}
                  >
                    今月のギフトによる収益額（累計）
                  </Typography>

                  <Typography variant="subtitle2" gutterBottom align="right">
                    {simpleReport.revenue.this_month.total_gift_revenue}円
                  </Typography>
                </Grid>
                {simpleReport.revenue.this_month.total_time_revenue != null && (
                  <Grid size={6} borderLeft={'1px solid #CCC;'} p={1}>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      align="center"
                      sx={{ fontWeight: 'bold' }}
                    >
                      今月の時給報酬額（累計）
                    </Typography>
                    <Typography variant="subtitle2" gutterBottom align="right">
                      {simpleReport.revenue.this_month.total_time_revenue}円
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Divider />
              <Grid size={{ xs: 4, sm: 8, md: 12 }} textAlign={'right'} padding={1}>
                <Button
                  variant="contained"
                  size="small"
                  component={RouterLink}
                  to={'/achievements'}
                  sx={{ bgcolor: '#FF4455' }}
                >
                  詳細レポート
                </Button>
              </Grid>
            </Item>
          </Grid>
          <Grid size={{ xs: 4, sm: 8, md: 6 }}>
            <Item
              sx={{
                p: 2,
              }}
            >
              <Typography variant="subtitle1" align="left">
                <InfoIcon fontSize="small" style={{ verticalAlign: -3 }} />
                お知らせ
              </Typography>
              <Divider />
              <TableContainer sx={{ maxHeight: 400 }}>
                <Table stickyHeader aria-label="sticky table">
                  <TableBody>
                    {news.length > 0
                      ? news.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell
                              sx={{
                                padding: 0,
                                py: 1,
                                width: 100,
                                verticalAlign: 'top',
                                fontWeight: 'bold',
                              }}
                            >
                              {row.created_at.substring(0, 10)}
                            </TableCell>
                            <TableCell sx={{ padding: 0, paddingLeft: 1, py: 1 }} align="left">
                              <span style={{ fontWeight: 'bold' }}>{row.title}</span>
                              <br />
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: row.body,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      : 'お知らせがありません。'}
                  </TableBody>
                </Table>
              </TableContainer>
            </Item>
          </Grid>
          <Grid size={{ xs: 4, sm: 8, md: 6 }}>
            <Box>
              <Grid size={{ xs: 4, sm: 8, md: 12 }} textAlign={'right'}>
                <Typography variant="caption" align="right" fontSize={12}>
                  ※有効配信時間⋯1回30分以上配信した場合の、1日上限2時間までとした配信時間
                </Typography>
              </Grid>
              <Grid size={{ xs: 4, sm: 8, md: 12 }} textAlign={'right'}>
                <Typography variant="caption" align="right" fontSize={12}>
                  ※有効配信日数⋯有効配信時間が発生した日を1日と数えたときの日数
                </Typography>
              </Grid>
            </Box>
            {simpleReport.revenue.this_month.total_time_revenue != null && (
              <Box>
                <Grid size={{ xs: 4, sm: 8, md: 12 }} textAlign={'right'}>
                  <Typography variant="caption" align="right" fontSize={12}>
                    ※今月の時給報酬額（累計）は未確定です。1ヶ月あたり最大40時間の有効配信時間に対して報酬が獲得できます。
                  </Typography>
                </Grid>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Dashbord;
