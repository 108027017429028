import {
  CircularProgress,
  Grid2 as Grid,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Link as RouterLink, useParams } from 'react-router-dom';
import AchievementType from '../../types/AchievementType';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja'); // カレンダーの曜日のフォーマット

/**
 * 配信実績画面
 * @returns 配信実績のJSX
 */
const Achievements = () => {
  //実績のstate
  const [achievement, setAchievement] = useState<AchievementType | null>(null);
  //実績取得日のstate
  const [date, setDate] = useState<dayjs.Dayjs | null>(dayjs(new Date().toISOString()));
  // URLのパラメータを取得する
  const params = useParams();

  /**
   * 日付型を検索用にタイムゾーン付きの月初(１日)の値に変換する
   *
   * @param date
   * @returns
   */
  function toISOStringYearMonthWithTimezone(date: Date) {
    const pad = (str: string) => {
      return ('0' + str).slice(-2);
    };
    const year = date.getFullYear().toString();
    const month = pad((date.getMonth() + 1).toString());
    const day = '01';
    const hour = '00';
    const min = '00';
    const sec = '00';
    const tz = -date.getTimezoneOffset();
    const sign = tz >= 0 ? '+' : '-';
    const tzHour = pad((tz / 60).toString());
    const tzMin = pad((tz % 60).toString());

    return `${year}-${month}-${day}T${hour}:${min}:${sec}${sign}${tzHour}:${tzMin}`;
  }

  /**
   * 配信時間（秒）を時刻形式に変換する
   *
   * @param seconds
   * @returns
   */
  function secToHMS(seconds: number) {
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor((seconds % 3600) / 60);
    const sec = seconds % 60;
    let hh;
    // hour が3桁以上の場合は左0埋めをしない
    if (hour < 100) {
      hh = `00${hour}`.slice(-2);
    } else {
      hh = hour;
    }
    const mm = `00${min}`.slice(-2);
    const ss = `00${sec}`.slice(-2);
    const time = `${hh}:${mm}:${ss}`;
    return time;
  }

  /**
   * 初期表示時は現在月で実績を検索
   * 年月日を変更した場合、
   */
  useEffect(() => {
    if (date != null) {
      const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000/';
      const param = {
        data: {
          creator_id: 'CqeyvsIKmIQy5VZ5WmaG', //テスト中のためクリエイター一覧は固定
          year_month: toISOStringYearMonthWithTimezone(date.toDate()),
        },
      };
      const config = {
        headers: {
          'content-type': 'application/json',
        },
      };
      axios
        .post(api_base_url + 'livecreatorPortalGetCreatorLiveHistory', param, config)
        .then((response) => {
          const data: AchievementType = response.data;
          setAchievement(data);
        })
        .catch((e) => {
          //配信実績がないとエラーになるので、エラーの場合は配信実績なしとする。
          setAchievement(new AchievementType());
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [null, date]);

  if (achievement == null) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  //日付変更部分のJSX
  const datePickerTsx = (
    <Grid width={'200px'} paddingBottom={'10px'}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer components={['DatePicker']}>
          <DatePicker
            label="実績検索日"
            format="YYYY/MM"
            value={date}
            slotProps={{ calendarHeader: { format: 'YYYY年MM月' } }}
            views={['year', 'month']}
            onChange={(e: any) => {
              try {
                e.toISOString();
                setDate(e);
              } catch (ex) {}
            }}
          />
        </DemoContainer>
      </LocalizationProvider>
    </Grid>
  );

  //配信実績がない場合の表示
  if (achievement.live_history.length === 0) {
    return (
      <>
        {datePickerTsx}
        配信実績がありません。
      </>
    );
  }

  return (
    <>
      {datePickerTsx}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>タイトル</TableCell>
              <TableCell>開始時間</TableCell>
              <TableCell>配信時間</TableCell>
              <TableCell>配信成績</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {achievement.live_history.map((row) => (
              <TableRow key={row._id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell>{row._source.channel_title}</TableCell>
                <TableCell>{new Date(row._source.channel_start_at).toLocaleString()}</TableCell>
                <TableCell align="right">{secToHMS(row._source.elapsed_time_seconds)}</TableCell>
                <TableCell>
                  <Link component={RouterLink} to={'/score/' + row._source.channel_id}>
                    成績確認へ
                  </Link>
                </TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>月間合計(配信時間)</TableCell>
              <TableCell align="right">{secToHMS(achievement.total_time_seconds)}</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>月間合計(配信日数)</TableCell>
              <TableCell align="right">{achievement.total_days_num.toLocaleString()}日</TableCell>
              <TableCell />
            </TableRow>
            <TableRow>
              <TableCell>月間合計(ギフトによる収益額)</TableCell>
              <TableCell align="right">{achievement.total_revenue.toLocaleString()}円</TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default Achievements;
