import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

interface Props {
  setMobileOpen?: (open: boolean) => void;
}

/**
 * ログイン時の読み込み途中にダミーで出すメニュー部のJSX
 *
 * @param param0
 * @returns
 */
const DummyMenu = ({ setMobileOpen }: Props) => {
  const doNothing = (open: boolean) => {};
  const setOpen = setMobileOpen ?? doNothing;
  const menus = [
    {
      text: 'ダッシュボード',
      pathname: '/',
      icon: HistoryEduIcon,
      testid: 'dashbord',
    },
    {
      text: '配信実績確認',
      pathname: '/achievements',
      icon: EmojiEventsIcon,
      testid: 'achievements',
    },
  ];

  return (
    <div>
      <Divider />
      <List>
        {menus.map((menu: any) => (
          <ListItemButton key={menu.text} onClick={() => setOpen(false)} data-testid={menu.testid}>
            <ListItemIcon sx={{ minWidth: 40 }}>
              <menu.icon />
            </ListItemIcon>
            <ListItemText primary={menu.text} />
          </ListItemButton>
        ))}
        <Divider />
        <span onClick={() => setOpen(false)}>
          <ListItemButton key="account" data-testid="account">
            <ListItemIcon sx={{ minWidth: 40 }}>
              <AccountCircleIcon />
            </ListItemIcon>
            <ListItemText primary="登録情報確認" />
          </ListItemButton>
        </span>
      </List>
    </div>
  );
};

export default DummyMenu;
