class ScoreType {
  channel_id: string; //成績を特定するID
  channel_title: string; //タイトル
  channel_start_at: string; //配信開始時間
  elapsed_time_seconds: number; //配信時間
  total_hearts_num: number; //ハート数
  total_gifts_num: number; //ギフト数
  total_comments_num: number; //コメント数
  max_concurrent_audience_users_num: number; //最大視聴者人数
  total_audience_users_num: number; //トータル視聴者人数

  constructor() {
    this.channel_id = '';
    this.channel_title = '';
    this.channel_start_at = '';
    this.elapsed_time_seconds = 0;
    this.total_hearts_num = 0;
    this.total_comments_num = 0;
    this.total_gifts_num = 0;
    this.max_concurrent_audience_users_num = 0;
    this.total_audience_users_num = 0;
  }
}

export default ScoreType;
