import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import { auth, googleAuthProvider, appleAuthProvider } from '../../FirebaseConfig';
import { signInWithPopup, signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { Divider } from '@mui/material';
import AppleIcon from '@mui/icons-material/Apple';
import { GoogleIcon } from '../parts/CustomeIcon';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  gap: theme.spacing(2),
  margin: 'auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '450px',
  },
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

const SignInContainer = styled(Stack)(({ theme }) => ({
  height: 'calc((1 - var(--template-frame-height, 0)) * 100dvh)',
  minHeight: '100%',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
  '&::before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    zIndex: -1,
    inset: 0,
    backgroundImage: 'radial-gradient(ellipse at 50% 50%, hsl(210, 100%, 97%), hsl(0, 0%, 100%))',
    backgroundRepeat: 'no-repeat',
    ...theme.applyStyles('dark', {
      backgroundImage: 'radial-gradient(at 50% 50%, hsla(210, 100%, 16%, 0.5), hsl(220, 30%, 5%))',
    }),
  },
}));

/**
 * ログイン画面
 * @returns ログインのJSX
 */
export default function Login() {
  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [smsCheck, setSmeCheck] = React.useState(false);
  const [smsCode, setSmsCode] = React.useState('');

  /**
   * 電話番号認証の処理
   * @param e
   */
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
    });

    // 電話番号を国際電話番号形式に変換
    let phone_number = phoneNumber.replaceAll('-', '');
    if (phone_number.startsWith('0')) {
      phone_number = phone_number.replace('0', '+81');
    }

    try {
      await signInWithPhoneNumber(auth, phone_number, recaptchaVerifier).then(
        function (confirmationResult) {
          (window as any).confirmationResult = confirmationResult;
          setSmeCheck(true);
        },
      );
    } catch (error) {
      alert(error);
    }
  };

  /**
   * 確認コードの処理
   */
  const checkSMSCode = () => {
    (window as any).confirmationResult
      .confirm(smsCode)
      .then(() => {
        // User signed in successfully.
        // const user = result.user;
        // ...
      })
      .catch(() => {
        alert('確認コードが間違っています。');
      });
  };

  /**
   * Googleログインの処理
   */
  const handleGoogleLogin = async () => {
    try {
      googleAuthProvider.addScope('profile');
      googleAuthProvider.addScope('email');
      await signInWithPopup(auth, googleAuthProvider);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * アップルIDのログイン処理
   */
  const handleAppleLogin = async () => {
    try {
      appleAuthProvider.addScope('email');
      appleAuthProvider.addScope('name');
      await signInWithPopup(auth, appleAuthProvider);
    } catch (error) {
      console.log(error);
    }
  };

  if (smsCheck) {
    return (
      <>
        <CssBaseline enableColorScheme />
        <SignInContainer direction="column" justifyContent="space-between">
          <Card variant="outlined">
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: 2,
              }}
            >
              <FormControl>
                <FormLabel htmlFor="phoneNumber">確認コード</FormLabel>
                <TextField
                  id="smsCode"
                  type="text"
                  name="smsCode"
                  required
                  fullWidth
                  variant="outlined"
                  sx={{ ariaLabel: 'email' }}
                  onChange={(e) => setSmsCode(e.target.value)}
                />
              </FormControl>

              <Button onClick={() => checkSMSCode()} fullWidth variant="contained">
                確認
              </Button>

              <div id="recaptcha-container" className="justify-center flex"></div>
            </Box>
          </Card>
        </SignInContainer>
      </>
    );
  }

  return (
    <>
      <CssBaseline enableColorScheme />
      <SignInContainer direction="column" justifyContent="space-between">
        <Card variant="outlined">
          <Typography
            component="h1"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(2rem, 10vw, 2.15rem)' }}
          >
            ログイン
          </Typography>

          <Typography
            component="p"
            variant="h4"
            sx={{ width: '100%', fontSize: 'clamp(1rem, 5vw, 1.2rem)' }}
          >
            登録したアカウントでログインしてください
          </Typography>

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => handleGoogleLogin()}
              startIcon={<GoogleIcon />}
            >
              Sign in with Google
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => handleAppleLogin()}
              startIcon={<AppleIcon />}
            >
              Sign in with Apple ID
            </Button>
          </Box>
          <Divider>or</Divider>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              gap: 2,
            }}
          >
            <FormControl>
              <FormLabel htmlFor="phoneNumber">電話番号</FormLabel>
              <TextField
                id="phoneNumber"
                type="text"
                name="phoneNumber"
                placeholder="09012345678"
                autoFocus
                required
                fullWidth
                variant="outlined"
                sx={{ ariaLabel: 'email' }}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>

            <Button type="submit" fullWidth variant="contained">
              電話番号 ログイン
            </Button>

            <div id="recaptcha-container" className="justify-center flex"></div>
          </Box>
        </Card>
      </SignInContainer>
    </>
  );
}
