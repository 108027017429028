import axios from 'axios';
import { useEffect, useState } from 'react';
import AgencyType from '../../types/AgencyType';
import AccountType from '../../types/AccountType';
import {
  CircularProgress,
  Grid2 as Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

/**
 * アカウント画面
 * @returns アカウントのJSX
 */
const Account = () => {
  const [account, setAccount] = useState<AccountType | null>(null);

  const agency: AgencyType = account != null ? account.agency : new AgencyType();

  useEffect(() => {
    const api_base_url = process.env.REACT_APP_PROXY_BASE_URL ?? 'http://localhost:3000/';
    axios.get(api_base_url + 'livecreatorPortalGetCreatorInformation').then((response) => {
      setAccount(response.data);
    });
  }, []);

  // アカウント情報が取れていない段階はロード中を表示
  if (account == null) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Typography sx={{ textAlign: 'center', fontSize: 25, fontWeight: 'medium' }}>
        基本情報
      </Typography>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              氏名
            </TableCell>
            <TableCell>{account.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              氏名（フリガナ）
            </TableCell>
            <TableCell>{account.name_furigana}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              年齢
            </TableCell>
            <TableCell>{account.age}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              メールアドレス
            </TableCell>
            <TableCell>{account.email_address}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              電話番号
            </TableCell>
            <TableCell>{account.phone_number}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              所属
            </TableCell>
            <TableCell>{agency.name}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Typography sx={{ textAlign: 'center', fontSize: 25, fontWeight: 'medium', paddingTop: 5 }}>
        その他の情報
      </Typography>
      <Table aria-label="simple table">
        <TableBody>
          <TableRow>
            <TableCell component="th" scope="row">
              ユーザー名
            </TableCell>
            <TableCell>{account.userinfo.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              認証種別
            </TableCell>
            <TableCell>
              {(() => {
                if (account.grade.type === 'VERIFIED') {
                  return <>認証</>;
                } else if (account.grade.type === 'REGISTERD') {
                  return <>登録（準認証）</>;
                }
              })()}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell component="th" scope="row">
              グレード
            </TableCell>
            <TableCell>
              {(() => {
                if (account.grade.value === 1) {
                  return <>Ⅰ</>;
                } else if (account.grade.value === 2) {
                  return <>Ⅱ</>;
                } else if (account.grade.value === 3) {
                  return <>Ⅲ</>;
                } else if (account.grade.value === 4) {
                  return <>Ⅳ</>;
                } else if (account.grade.value === 5) {
                  return <>Ⅴ</>;
                } else if (account.grade.value === 6) {
                  return <>Ⅵ</>;
                } else {
                  return <>-</>;
                }
              })()}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};

export default Account;
